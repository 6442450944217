<template>
  <div>
    <b-table
      hover
      responsive
      :per-page="per_page"
      :current-page="current_page"
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      show-empty
      @row-clicked="onRowClicked"
    >
      <!-- CUSTOM SHOW EMPTY DATA -->
      <template #empty="scope">
        <center>
          <h6>{{ scope.emptyText }}</h6>
        </center>
      </template>

      <!-- CUSTOM SHOW EMPTY FILTERED -->
      <template #emptyfiltered="scope">
        <center>
          <h6>{{ scope.emptyFilteredText }}</h6>
        </center>
      </template>

      <!-- CUSTOM CELL DETAILS -->
      <template #cell(details)="row">
        <feather-icon
          v-if="!row.detailsShowing"
          icon="ChevronRightIcon"
          size="20"
        />
        <feather-icon v-else icon="ChevronDownIcon" size="20" />
      </template>

      <!-- CUSTOM CELL ROW DETAILS -->
      <template #row-details>
        <div class="mx-5 px-3 mt-2 mb-2">
          <!-- ADD TOPIK BUTTON -->
          <b-button
            class="float-right mb-1"
            variant="outline-primary"
            v-b-modal.tambah-topiks
          >
            <feather-icon icon="PlusIcon" /> Tambah Topik
          </b-button>

          <!-- TABLE -->
          <b-table :items="data.childItems" :fields="data.childFields">
            <template #cell(actions)="">
              <!-- EDIT BUTTON -->
              <b-button variant="outline-info" size="sm" class="btn-icon mr-50">
                <feather-icon icon="EditIcon" />
              </b-button>

              <!-- DELETE BUTTON -->
              <b-button
                variant="outline-danger"
                size="sm"
                class="btn-icon"
                @click="deleteGroup()"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </div>
      </template>

      <!-- CUSTOM CELL ACTION -->
      <template #cell(action)="data">
        <div>
          <!-- EDIT BUTTON -->
          <b-button
            variant="outline-info"
            size="sm"
            v-b-tooltip.hover.v-dark="'Edit'"
            class="btn-icon mr-50"
            @click="editData(data.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <!-- DELETE BUTTON -->
          <b-button
            variant="outline-danger"
            size="sm"
            v-b-tooltip.hover.v-dark="'Hapus'"
            class="btn-icon"
            @click="deleteData(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>

    <!-- ROW INFORMATION AND SELECT ROW -->
    <div class="d-flex mt-2">
      <div class="d-flex align-items-center mr-auto ml-2">
        <div>Showing {{ current_page }} to</div>
        <div class="ml-1 mr-1">
          <b-form-select v-model="per_page" :options="page_options" />
        </div>
        <div>
          of
          {{ data.items.length }} Entries
        </div>
      </div>

      <!-- PAGINATION -->
      <div class="ml-auto mt-1 mr-2">
        <b-pagination
          v-model="current_page"
          :total-rows="data.items.length"
          :per-page="per_page"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

export default {
  name: "keywordDetailTable",
  components: {
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    VBTooltip,
  },

  props: {
    data: Object,
    editData: Function,
    deleteData: Function,
  },

  data() {
    return {
      per_page: this.data.row,
      page_options: [3, 5, 10],
      current_page: 1,
    };
  },

  methods: {
    onRowClicked(item) {
      item._showDetails = !item._showDetails;
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
 