<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <validation-observer ref="keywordForm">
      <b-form>
        <!-- GROUP -->
        <b-form-group>
          <validation-provider
            v-if="data.media == 'Konvensional'"
            #default="{ errors }"
            name="Group"
            rules="required"
          >
            <v-select
              v-model="group"
              :options="group_options"
              placeholder="Pilih Group"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- TOPIK -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Topik"
            rules="required"
          >
            <v-select
              v-model="topik"
              :options="topik_options"
              placeholder="Pilih Topik"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- KEYWORD / HASHTAG -->
        <b-form-group>
          <!-- HASHTAG -->
          <validation-provider
            v-if="data.id == 'add-hashtag' || data.id == 'edit-hashtag'"
            #default="{ errors }"
            name="Hashtag"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="hashtag"
                type="text"
                placeholder="Masukan Hashtag"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- KEYWORD -->
          <validation-provider
            v-else
            #default="{ errors }"
            name="Keyword"
            rules="required"
          >
            <b-form-input
              v-model="keyword"
              type="text"
              :placeholder="'Masukkan Keyword'"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- GUIDE 1 -->
          <div class="d-flex align-items-center mt-05 text-muted">
            <feather-icon icon="AlertCircleIcon" />
            <small class="ml-05 text-justify mr-05"
              >Gunakan tanda kutip untuk menjadikan keyword sebagai Frasa</small
            >
          </div>

          <!-- GUIDE 2 -->
          <div
            v-if="
              data.media == 'Sosial' &&
              (data.type == 'add-keyword' || data.type == 'edit-keyword')
            "
            class="d-flex align-items-center mt-05 text-muted"
          >
            <feather-icon icon="AlertCircleIcon" />
            <small class="ml-05 text-justify mr-05"
              >Keyword hanya untuk
              <strong>Twitter, Facebook, Youtube, Forum, Blog</strong> dan
              <strong>Linkedin</strong></small
            >
          </div>

          <!-- GUIDE 3 -->
          <div
            v-if="data.type == 'add-hashtag' || data.type == 'edit-hashtag'"
            class="d-flex align-items-center mt-05 text-muted"
          >
            <feather-icon icon="AlertCircleIcon" />
            <small class="ml-05 text-justify mr-05"
              >Hashtag hanya untuk <strong>Instagram</strong> dan
              <strong>Tiktok</strong></small
            >
          </div>

          <!-- GUIDE 4 -->
          <div
            v-if="data.id == 'add-hashtag' || data.id == 'edit-hashtag'"
            class="d-flex align-items-center mt-05 text-muted"
          >
            <feather-icon icon="AlertCircleIcon" />
            <small class="ml-05">Masukkan Hashtag tanpa tanda #</small>
          </div>
        </b-form-group>

        <!-- KEYWORD EXCLUDE -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Keyword yang akan di Exclude"
            rules="required"
          >
            <b-form-input
              v-model="keyword_exclude"
              type="text"
              placeholder="Masukkan Keyword yang akan di Exclude"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- GUIDE 5 -->
          <div class="d-flex mt-05 text-muted">
            <feather-icon icon="AlertCircleIcon" size="22" />
            <small class="ml-05 text-justify mr-05"
              >Keyword yang diexclude akan dianggap sebagai frasa, gunakan koma
              untuk memisahkan setiap keyword</small
            >
          </div>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  name: "keywordFormModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    VBModal,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      required,
      group: null,
      topik: null,
      keyword: null,
      hashtag: null,
      keyword_exclude: null,
      group_options: ["Group 1", "Group 2"],
      topik_options: ["Topik 1", "Topik 2"],
    };
  },

  methods: {
    validationForm() {
      this.$refs.keywordForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>