<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Hashtag </b-card-title>
      <!-- HELP ICON -->
      <feather-icon
        icon="HelpCircleIcon"
        size="20"
        class="mr-auto ml-1"
        v-b-tooltip.hover.html="helpField"
      />

      <!-- EXPORT HASHTAG BUTTON -->
      <b-button variant="outline-warning" class="ml-auto mr-1">
        <feather-icon icon="DownloadIcon" class="mr-50" />Export Hashtag
      </b-button>

      <!-- ADD HASHTAG BUTTON -->
      <b-button variant="outline-primary" class="mr-1" @click="addHashtag">
        <feather-icon icon="PlusIcon" class="mr-50" />Tambah Hashtag
      </b-button>

      <!-- FORM SEARCH -->
      <div class="w-25">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Cari"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </div>
    </b-card-header>
    <b-card-body>
      <div>
        <!-- LIMIT HASHTAG VALUE -->
        <label>
          <strong>
            <h6>Limit Hashtag Instagram dan Tiktok (Terpakai : 5)</h6>
          </strong>
        </label>

        <!-- HASHTAG PROGRESS BAR -->
        <b-progress
          :max="max"
          class="progress-bar-primary"
          style="margin-bottom: 5px"
        >
          <b-progress-bar
            :value="value"
            :label="`${((value / max) * 100).toFixed()}%`"
            variant="primary"
          />
        </b-progress>
      </div>
      <!-- MINIMUM VALUE -->
      <strong class="float-left"><h6>0</h6></strong>

      <!-- MAXIMUM VALUE -->
      <strong class="float-right"><h6>20</h6></strong>
    </b-card-body>
    <!-- TABLE COMPONENT -->
    <keywordTable
      :data="table_data"
      :editData="editHashtag"
      :deleteData="deleteHashtag"
      class="mb-2"
    />

    <!-- ADD HASHTAG FORM MODAL COMPONENT -->
    <keywordFormModal :data="modal_data" />

    <!-- EDIT HASHTAG FORM MODAL COMPONENT -->
    <keywordFormModal :data="edit_modal_data" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  VBTooltip,
  BProgress,
  BProgressBar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import keywordTable from "../keywordTable.vue";
import keywordFormModal from "../keywordFormModal.vue";

export default {
  name: "cardHashtag",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BProgress,
    BProgressBar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ToastificationContent,
    keywordTable,
    keywordFormModal,
  },

  data() {
    return {
      value: 25,
      max: 100,
      modal_data: {
        title: "Tambah Hashtag",
        media: "Sosial",
        type: "add-hashtag",
        id: "add-hashtag",
      },
      edit_modal_data: {
        title: "Edit Hashtag",
        media: "Sosial",
        type: "edit-hashtag",
        id: "edit-hashtag",
      },
      helpField: {
        title:
          "<p align=left>Grup digunakan untuk mengelompokkan beberapa topik menjadi satu. Hanya topik media konvensional yang dapat dikelompokkan.</p><p align=left>Topik digunakan untuk mengelompokkan beberapa keyword menjadi satu</p>",
      },
      table_data: {
        type: "keyword",
        row: 5,
        filter: null,
        fields: [
          {
            key: "topik",
            label: "Topik",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "hashtag",
            label: "Hashtag",
            thClass: "text-center",
            tdClass: "text-center font-weight-bolder",
            sortable: true,
            thStyle: { width: "22%", paddingRight: "2rem" },
          },
          {
            key: "excludes",
            label: "Excludes",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "22%", paddingRight: "2rem" },
          },
          {
            key: "Action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%" },
          },
        ],
        items: [
          {
            id: 1,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 2,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 3,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 4,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 5,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 6,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 7,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 8,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 9,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
          {
            id: 10,
            topik: "Vue Project",
            hashtag: "#hashtag Pertama",
            excludes: "-",
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    addHashtag() {
      this.$bvModal.show("add-hashtag");
    },
    editHashtag(id) {
      this.$bvModal.show("edit-hashtag");
    },
    deleteHashtag(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Menghapus <strong>Group</strong> akan menghapus semua Topik  dan Keyword  yang ada didalamnya </div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "Group Telah Dihapus", 5000);
        }
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>