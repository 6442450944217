var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.data.id,"title":_vm.data.title,"ok-title":"Simpan","cancel-title":"Batal","title-tag":"h4","cancel-variant":"outline-danger","ok-variant":"info","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.validationForm()}}},[_c('validation-observer',{ref:"keywordForm"},[_c('b-form',[_c('b-form-group',[(_vm.data.media == 'Konvensional')?_c('validation-provider',{attrs:{"name":"Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.group_options,"placeholder":"Pilih Group"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1468188801)}):_vm._e()],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Topik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.topik_options,"placeholder":"Pilih Topik"},model:{value:(_vm.topik),callback:function ($$v) {_vm.topik=$$v},expression:"topik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[(_vm.data.id == 'add-hashtag' || _vm.data.id == 'edit-hashtag')?_c('validation-provider',{attrs:{"name":"Hashtag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HashIcon"}})],1),_c('b-form-input',{attrs:{"type":"text","placeholder":"Masukan Hashtag"},model:{value:(_vm.hashtag),callback:function ($$v) {_vm.hashtag=$$v},expression:"hashtag"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3125014667)}):_c('validation-provider',{attrs:{"name":"Keyword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":'Masukkan Keyword'},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"d-flex align-items-center mt-05 text-muted"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',{staticClass:"ml-05 text-justify mr-05"},[_vm._v("Gunakan tanda kutip untuk menjadikan keyword sebagai Frasa")])],1),(
            _vm.data.media == 'Sosial' &&
            (_vm.data.type == 'add-keyword' || _vm.data.type == 'edit-keyword')
          )?_c('div',{staticClass:"d-flex align-items-center mt-05 text-muted"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',{staticClass:"ml-05 text-justify mr-05"},[_vm._v("Keyword hanya untuk "),_c('strong',[_vm._v("Twitter, Facebook, Youtube, Forum, Blog")]),_vm._v(" dan "),_c('strong',[_vm._v("Linkedin")])])],1):_vm._e(),(_vm.data.type == 'add-hashtag' || _vm.data.type == 'edit-hashtag')?_c('div',{staticClass:"d-flex align-items-center mt-05 text-muted"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',{staticClass:"ml-05 text-justify mr-05"},[_vm._v("Hashtag hanya untuk "),_c('strong',[_vm._v("Instagram")]),_vm._v(" dan "),_c('strong',[_vm._v("Tiktok")])])],1):_vm._e(),(_vm.data.id == 'add-hashtag' || _vm.data.id == 'edit-hashtag')?_c('div',{staticClass:"d-flex align-items-center mt-05 text-muted"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',{staticClass:"ml-05"},[_vm._v("Masukkan Hashtag tanpa tanda #")])],1):_vm._e()],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Keyword yang akan di Exclude","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Masukkan Keyword yang akan di Exclude"},model:{value:(_vm.keyword_exclude),callback:function ($$v) {_vm.keyword_exclude=$$v},expression:"keyword_exclude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"d-flex mt-05 text-muted"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon","size":"22"}}),_c('small',{staticClass:"ml-05 text-justify mr-05"},[_vm._v("Keyword yang diexclude akan dianggap sebagai frasa, gunakan koma untuk memisahkan setiap keyword")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }