<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <div class="d-flex">
      <!-- SEARCH FORM -->
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <i class="bi bi-facebook facebook-color"></i>
        </b-input-group-prepend>
        <b-form-input
          placeholder="Masukkan Nama Facebook Page"
          v-model="search"
        />
      </b-input-group>

      <!-- SEARCH BUTTON -->
      <div>
        <b-button variant="primary" class="ml-1" @click="searchButton"
          >Cari</b-button
        >
      </div>
    </div>
    <b-list-group class="my-2">
      <b-list-group-item
        v-for="data in searchPage"
        :key="data.id"
        class="d-flex align-items-center"
      > 
        <!-- FACEBOOK PAGE TITLE -->
        <div>{{ data.title }}</div>

        <!-- ADD BUTTON -->
        <div class="ml-auto">
          <b-button variant="success" size="sm"> Tambahkan</b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div class="d-flex">
      <!-- BACK BUTTON -->
      <b-button variant="outline-primary" class="mr-auto">
        <feather-icon icon="ChevronLeftIcon" />
        <feather-icon icon="ChevronLeftIcon" style="margin-left: -10px" />
        Sebelumnya
      </b-button>

      <!-- CONTINUE BUTTON -->
      <b-button variant="outline-primary" class="ml-auto">
        Selanjutnya
        <feather-icon icon="ChevronRightIcon" />
        <feather-icon icon="ChevronRightIcon" style="margin-left: -10px" />
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "addListModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BButton,
    vSelect,
    VBModal,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      search: null,
      filter: "",
      data_page: [
        {
          id: 1,
          title: "Facebook Page 1",
        },
        {
          id: 2,
          title: "Facebook Page 2",
        },
        {
          id: 3,
          title: "Facebook Page 3",
        },
        {
          id: 4,
          title: "Facebook Page 4",
        },
        {
          id: 5,
          title: "Facebook Page 5",
        },
      ],
    };
  },

  methods: {
    searchButton() {
      this.filter = this.search;
    },
  },

  computed: {
    searchPage() {
      return this.data_page.filter((data) => {
        let response = data.title
          .toLowerCase()
          .match(this.filter.toLowerCase());
        return response;
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>