<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Group dan Topik </b-card-title>
      <!-- HELP ICON -->
      <feather-icon
        icon="HelpCircleIcon"
        size="20"
        class="mr-auto ml-1"
        v-b-tooltip.hover.html="helpField"
      />

      <!-- ADD GROUP BUTTON -->
      <b-button
        variant="outline-primary"
        class="ml-auto mr-1"
        @click="addGroup"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />Tambah Group
      </b-button>

      <!-- FORM SEARCH -->
      <div class="w-25">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Cari"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </div>
    </b-card-header>

    <!-- TABLE COMPONENT -->
    <keywordDetailTable
      :data="table_data"
      :editData="editGroup"
      :deleteData="deleteGroup"
      class="mb-2"
    />

    <!-- ADD GROUP FORM MODAL COMPONENT -->
    <repeatFormModal :data="modal_data" />

    <!-- EDIT GROUP FORM MODAL COMPONENT -->
    <repeatFormModal :data="edit_modal_data" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import keywordDetailTable from "../keywordDetailTable.vue";
import repeatFormModal from "../repeatFormModal.vue";

export default {
  name: "cardGroupTopik",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ToastificationContent,
    keywordDetailTable,
    repeatFormModal,
  },

  data() {
    return {
      modal_data: {
        title: "Tambah Group",
        subtitle: "Group",
        type: "add",
        id: "add-group",
      },
      edit_modal_data: {
        title: "Edit Group",
        subtitle: "Group",
        type: "edit",
        id: "edit-group",
      },
      helpField: {
        title:
          "<p align=left>“Grup digunakan untuk mengelompokkan beberapa topik menjadi satu. Hanya topik media konvensional yang dapat dikelompokkan.Topik digunakan untuk mengelompokkan beberapa keyword menjadi satu.”</p>",
      },
      table_data: {
        type: "keyword",
        row: 5,
        filter: null,
        fields: [
          {
            key: "details",
            label: "",
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { width: "2%" },
          },
          {
            key: "group",
            label: "Group",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "jumlah_topik",
            label: "Jumlah Topik",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "jumlah_keyword",
            label: "Jumlah Keyword",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%" },
          },
        ],
        childFields: [
          {
            key: "topik",
            label: "Topik",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "jumlah_keyword",
            label: "Jumlah Keyword",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%" },
            sortable: true,
          },
          {
            key: "actions",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%" },
          },
        ],
        items: [
          {
            id: 1,
            group: "Angular",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
          {
            id: 2,
            group: "React",
            jumlah_topik: 10,
            jumlah_keyword: 10,
            _showDetails: false,
          },
          {
            id: 3,
            group: "Vue JS",
            jumlah_topik: 15,
            jumlah_keyword: 15,
            _showDetails: false,
          },
          {
            id: 4,
            group: "Bootstrap",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
          {
            id: 5,
            group: "Figma",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
          {
            id: 6,
            group: "Android",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
          {
            id: 7,
            group: "Native",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
          {
            id: 8,
            group: "Next",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
          {
            id: 9,
            group: "Nuxt",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
          {
            id: 10,
            group: "Laravel",
            jumlah_topik: 12,
            jumlah_keyword: 12,
            _showDetails: false,
          },
        ],
        childItems: [
          {
            id: 1,
            topik: "Laravel",
            jumlah_keyword: 12,
          },
          {
            id: 2,
            topik: "Laravel",
            jumlah_keyword: 12,
          },
          {
            id: 3,
            topik: "Laravel",
            jumlah_keyword: 12,
          },
          {
            id: 4,
            topik: "Laravel",
            jumlah_keyword: 12,
          },
          {
            id: 5,
            topik: "Laravel",
            jumlah_keyword: 12,
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    addGroup() {
      this.$bvModal.show("add-group");
    },
    editGroup(id) {
      this.$bvModal.show("edit-group");
    },
    deleteGroup(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Menghapus <strong>Group</strong> akan menghapus semua Topik  dan Keyword  yang ada didalamnya </div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "Group Telah Dihapus", 5000);
        }
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>

 