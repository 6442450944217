<template>
  <div>
    <b-card>
      <!-- SWITCH BUTTON MEDIA KONVENSIONAL IS ACTIVE -->
      <div class="d-flex" v-if="media === 'konvensional'">
        <b-button
          variant="primary"
          class="mr-2"
          v-on:click="media = 'konvensional'"
        >
          Media Konvensional
        </b-button>
        <b-button variant="flat" v-on:click="media = 'sosial'">
          Media Sosial
        </b-button>
      </div>

      <!-- SWITCH BUTTON MEDIA SOSIAL IS ACTIVE -->
      <div class="d-flex" v-else-if="media === 'sosial'">
        <b-button
          variant="flat"
          class="mr-2"
          v-on:click="media = 'konvensional'"
        >
          Media Konvensional
        </b-button>
        <b-button variant="primary" v-on:click="media = 'sosial'">
          Media Sosial
        </b-button>
      </div>
    </b-card>

    <!-- CONTENT OF MEDIA KONVENSIONAL -->
    <div v-if="media === 'konvensional'">
      <!-- GROUP AND TOPIK -->
      <cardGroupTopik />

      <!-- KEYWORD -->
      <cardKeyword />
    </div>

    <!-- CONTENT OF MEDIA SOSIAL -->
    <div v-else>
      <!-- TOPIK -->
      <cardTopik />

      <!-- KEYWORD -->
      <cardKeywordMediaSosial />

      <!-- HASHTAG -->
      <cardHashtag />

      <!-- FACEBOOK PAGE -->
      <cardPages />
    </div>
  </div>
</template>
<script>
import { BCard, BButton } from "bootstrap-vue";
import cardGroupTopik from "@/components/setting/keyword/medkon/cardGroupTopik.vue";
import cardKeyword from "@/components/setting/keyword/medkon/cardKeyword.vue";
import cardTopik from "@/components/setting/keyword/medsos/cardTopik.vue";
import cardKeywordMediaSosial from "@/components/setting/keyword/medsos/cardKeywordMediaSosial.vue";
import cardHashtag from "@/components/setting/keyword/medsos/cardHashtag.vue";
import cardPages from "@/components/setting/keyword/medsos/cardPages.vue";

export default {
  name: "Keyword",
  components: {
    BCard,
    BButton,
    cardGroupTopik,
    cardKeyword,
    cardTopik,
    cardKeywordMediaSosial,
    cardHashtag,
    cardPages,
  },

  data() {
    return {
      title: "Keyword | Online Media Monitoring",
      media: "konvensional",
    };
  },

  created() {
    document.title = this.title;
  },
};
</script>