<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Facebook Page </b-card-title>
      <!-- HELP ICON -->
      <feather-icon
        icon="HelpCircleIcon"
        size="20"
        class="mr-auto ml-1"
        v-b-tooltip.hover.html="helpField"
      />

      <!-- ADD HASHTAG BUTTON -->
      <b-button
        variant="outline-primary"
        class="ml-auto mr-1"
        @click="addFacebookHashtag"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />Tambah Hashtag
      </b-button>

      <!-- FORM SEARCH -->
      <div class="w-25">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Cari"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </div>
    </b-card-header>
    <b-card-body>
      <div>
        <!-- LIMIT FACEBOOK VALUE -->
        <label>
          <strong><h6>Limit Facebook Page (Terpakai : 5)</h6></strong>
        </label>

        <!-- FACEBOOK PROGRESS BAR -->
        <b-progress
          :max="max"
          class="progress-bar-primary"
          style="margin-bottom: 5px"
        >
          <b-progress-bar
            :value="value"
            :label="`${((value / max) * 100).toFixed()}%`"
            variant="primary"
          />
        </b-progress>
      </div>
      <!-- MINIMUM VALUE -->
      <strong class="float-left"><h6>0</h6></strong>

      <!-- MAXIMUM VALUE -->
      <strong class="float-right"><h6>20</h6></strong>
      <div class="d-flex align-items-center mt-3">
        <feather-icon icon="AlertCircleIcon" class="mr-50" />
        <div>
          Berikut merupakan daftar halaman facebook yang akan menjadi sumber
          data yang akan dikumpulkan. Selain data pada tabel dibawah, data juga
          akan diambil dari daftar
          <span class="text-primary clickable" v-b-modal.table-facebook
            >Halaman Facebook Default</span
          >
          .
        </div>
      </div>
    </b-card-body>
    <!-- TABLE COMPONENT -->
    <keywordTable
      :data="table_data"
      :editData="editFacebookHashtag"
      :deleteData="deleteFacebookHashtag"
      class="mb-2"
    />

    <!-- TABLE MODAL COMPONENT -->
    <tableModal :data="table_modal_data" />

    <!-- ADD FACEBOOK FORM MODAL COMPONENT -->
    <addListModal :data="modal_data" />

    <!-- EDIT FACEBOOK FORM MODAL COMPONENT -->
    <addListModal :data="edit_modal_data" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  VBTooltip,
  BProgress,
  BProgressBar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import keywordTable from "../keywordTable.vue";
import tableModal from "@/components/usable/tableModal.vue";
import addListModal from "../addListModal.vue";

export default {
  name: "cardPages",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BProgress,
    BProgressBar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ToastificationContent,
    keywordTable,
    tableModal,
    addListModal,
  },

  data() {
    return {
      value: 25,
      max: 100,
      modal_data: {
        title: "Tambah Facebook Page",
        media: "Sosial",
        type: "Facebook",
        id: "add-facebook-hashtag",
      },
      edit_modal_data: {
        title: "Edit Facebook Page",
        media: "Sosial",
        type: "Facebook",
        id: "edit-facebook-hashtag",
      },
      table_modal_data: {
        title: "Facebook Page",
        type: "Facebook",
        id: "table-facebook",
        table_data: {
          row: 5,
          fields: [
            {
              key: "facebook_page",
              label: "Facebook Page",
              thClass: "text-left",
              tdClass: "text-left",
            },
          ],
          items: [
            {
              id: 1,
              facebook_page: "Vue Project",
            },
            {
              id: 2,
              facebook_page: "Vue Project",
            },
            {
              id: 3,
              facebook_page: "Vue Project",
            },
            {
              id: 4,
              facebook_page: "Vue Project",
            },
            {
              id: 5,
              facebook_page: "Vue Project",
            },
            {
              id: 6,
              facebook_page: "Vue Project",
            },
            {
              id: 7,
              facebook_page: "Vue Project",
            },
            {
              id: 8,
              facebook_page: "Vue Project",
            },
            {
              id: 9,
              facebook_page: "Vue Project",
            },
            {
              id: 10,
              facebook_page: "Vue Project",
            },
          ],
        },
      },
      helpField: {
        title:
          "<p align=left>Grup digunakan untuk mengelompokkan beberapa topik menjadi satu. Hanya topik media konvensional yang dapat dikelompokkan.</p><p align=left>Topik digunakan untuk mengelompokkan beberapa keyword menjadi satu</p>",
      },
      table_data: {
        type: "facebook",
        row: 5,
        filter: null,
        fields: [
          {
            key: "facebook_page",
            label: "Facebook Page",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "Action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%" },
          },
        ],
        items: [
          {
            id: 1,
            facebook_page: "Vue Project",
          },
          {
            id: 2,
            facebook_page: "Vue Project",
          },
          {
            id: 3,
            facebook_page: "Vue Project",
          },
          {
            id: 4,
            facebook_page: "Vue Project",
          },
          {
            id: 5,
            facebook_page: "Vue Project",
          },
          {
            id: 6,
            facebook_page: "Vue Project",
          },
          {
            id: 7,
            facebook_page: "Vue Project",
          },
          {
            id: 8,
            facebook_page: "Vue Project",
          },
          {
            id: 9,
            facebook_page: "Vue Project",
          },
          {
            id: 10,
            facebook_page: "Vue Project",
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    addFacebookHashtag() {
      this.$bvModal.show("add-facebook-hashtag");
    },
    editFacebookHashtag(id) {
      this.$bvModal.show("edit-facebook-hashtag");
    },
    deleteFacebookHashtag(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Menghapus <strong>Group</strong> akan menghapus semua Topik  dan Keyword  yang ada didalamnya </div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "Group Telah Dihapus", 5000);
        }
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>