<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Keyword </b-card-title>
      <!-- HELP ICON -->
      <feather-icon
        icon="HelpCircleIcon"
        size="20"
        class="mr-auto ml-1"
        v-b-tooltip.hover.html="helpField"
      />

      <!-- EXPORT KEYWORD BUTTON -->
      <b-button variant="outline-warning" class="ml-auto mr-1">
        <feather-icon icon="DownloadIcon" class="mr-50" />Export Keyword
      </b-button>

      <!-- ADD KEYWORD BUTTON -->
      <b-button variant="outline-primary" class="mr-1" @click="addKeyword">
        <feather-icon icon="PlusIcon" class="mr-50" />Tambah Keyword
      </b-button>

      <!-- FORM SEARCH -->
      <div class="w-25">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Cari"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </div>
    </b-card-header>
    <!-- TABLE COMPONENT -->
    <keywordTable
      :data="table_data"
      :editData="editKeyword"
      :deleteData="deleteKeyword"
      class="mb-2"
    />

    <!-- ADD KEYWORD FORM MODAL COMPONENT -->
    <keywordFormModal :data="modal_data" />

    <!-- EDIT KEYWORD FORM MODAL COMPONENT -->
    <keywordFormModal :data="edit_modal_data" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import keywordTable from "../keywordTable.vue";
import keywordFormModal from "../keywordFormModal.vue";

export default {
  name: "cardKeyword",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ToastificationContent,
    keywordTable,
    keywordFormModal,
  },

  data() {
    return {
      modal_data: {
        title: "Tambah Keyword",
        media: "Konvensional",
        type: "add-keyword",
        id: "add-keyword-medkon",
      },
      edit_modal_data: {
        title: "Edit Keyword",
        media: "Konvensional",
        type: "edit-keyword",
        id: "edit-keyword-medkon",
      },
      helpField: {
        title:
          "<p align=left>“Keyword merupakan kata kunci yang digunakan dalam pencarian data. Sistem akan memonitor postingan media konvensional yang mengandung kata kunci tersebut.Crawler Media Cetak, TV, dan Online News akan menggunakan keyword ini. Jika keyword ini belum diisi, crawler akan menggunakan keyword pada media sosial.”</p>",
      },
      table_data: {
        type: "keyword",
        row: 5,
        fields: [
          {
            key: "group",
            label: "Group",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "topik",
            label: "Topik",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "keyword",
            label: "Keyword",
            thClass: "text-center",
            tdClass: "text-center font-weight-bolder",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "excludes",
            label: "Excludes",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%" },
          },
        ],
        items: [
          {
            id: 1,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 2,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 3,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 4,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 5,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 6,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 7,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 8,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 9,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
          {
            id: 10,
            group: "Vue Group",
            topik: "Vue Project",
            keyword: "Keyword Pertama",
            excludes: "-",
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    addKeyword(id) {
      this.$bvModal.show("add-keyword-medkon");
    },
    editKeyword(id) {
      this.$bvModal.show("edit-keyword-medkon");
    },
    deleteKeyword(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Menghapus <strong>Group</strong> akan menghapus semua Topik  dan Keyword  yang ada didalamnya </div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "Group Telah Dihapus", 5000);
        }
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>