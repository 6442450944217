<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Topik </b-card-title>
      <!-- HELP ICON -->
      <feather-icon
        icon="HelpCircleIcon"
        size="20"
        class="mr-auto ml-1"
        v-b-tooltip.hover.html="helpField"
      />

      <!-- ADD TOPIK BUTTON -->
      <b-button
        variant="outline-primary"
        class="ml-auto mr-1"
        @click="addTopik"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />Tambah Topik
      </b-button>

      <!-- FORM SEARCH -->
      <div class="w-25">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Cari"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </div>
    </b-card-header>
    <!-- TABLE COMPONENT -->
    <keywordTable
      :data="table_data"
      :editData="editTopik"
      :deleteData="deleteTopik"
      class="mb-2"
    />

    <!-- ADD TOPIK FORM MODAL COMPONENT -->
    <repeatFormModal :data="modal_data" />

    <!-- EDIT TOPIK FORM MODAL COMPONENT -->
    <repeatFormModal :data="edit_modal_data" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import keywordTable from "../keywordTable.vue";
import repeatFormModal from "../repeatFormModal.vue";

export default {
  name: "cardTopik",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ToastificationContent,
    keywordTable,
    repeatFormModal,
  },

  data() {
    return {
      modal_data: {
        title: "Tambah Topik",
        subtitle: "Topik",
        type: "add",
        id: "add-topik",
      },
      edit_modal_data: {
        title: "Edit Topik",
        subtitle: "Topik",
        type: "edit",
        id: "edit-topik",
      },
      helpField: {
        title:
          "<p align=left>Grup digunakan untuk mengelompokkan beberapa topik menjadi satu. Hanya topik media konvensional yang dapat dikelompokkan.</p><p align=left>Topik digunakan untuk mengelompokkan beberapa keyword menjadi satu</p>",
      },
      table_data: {
        type: "keyword",
        row: 5,
        filter: null,
        fields: [
          {
            key: "topik",
            label: "Topik",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "jumlah_keyword",
            label: "Jumlah Keyword",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "22%", paddingRight: "2rem" },
          },
          {
            key: "jumlah_hashtag",
            label: "Jumlah Hashtag",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "22%", paddingRight: "2rem" },
          },
          {
            key: "Action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%" },
          },
        ],
        items: [
          {
            id: 1,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 2,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 3,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 4,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 5,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 6,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 7,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 8,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 9,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
          {
            id: 10,
            topik: "Vue Group",
            jumlah_keyword: 666,
            jumlah_hashtag: 888,
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    addTopik() {
      this.$bvModal.show("add-topik");
    },
    editTopik(id) {
      this.$bvModal.show("edit-topik");
    },
    deleteTopik(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Menghapus <strong>Group</strong> akan menghapus semua Topik  dan Keyword  yang ada didalamnya </div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "Group Telah Dihapus", 5000);
        }
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>