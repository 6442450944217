<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    title-tag="h4"
    ok-title="Simpan"
    no-close-on-backdrop
  >
    <validation-observer ref="repeatForm">
      <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
        <b-row
          v-for="(item, index) in item"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- ADD GROUP / TOPIK-->
          <b-col
            v-if="data.id == 'add-group' || data.id == 'add-topik'"
            md="10"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="'Nama ' + data.subtitle"
                rules="required"
              >
                <b-form-input
                  type="text"
                  :placeholder="'Masukkan Nama ' + data.subtitle"
                  v-model="group_name[index]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- EDIT GROUP / TOPIK-->
          <b-col v-else md="12">
            <b-form-group>
              <!-- NAME -->
              <validation-provider
                #default="{ errors }"
                :name="'Nama ' + data.subtitle"
                rules="required"
              >
                <b-form-input
                  type="text"
                  :placeholder="'Masukkan Nama ' + data.subtitle"
                  v-model="group_name[index]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="2" class="mb-50">
            <!-- DELETE BUTTON -->
            <b-button
              v-if="item.id != 1"
              variant="outline-danger"
              class="btn-icon"
              @click="removeItem(index)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- CUSTOM MODAL FOOTER -->
    <template #modal-footer>
      <div class="w-100">
        <!-- ADD NEW BUTTON -->
        <b-button
          variant="outline-primary"
          v-if="data.id == 'add-group' || data.id == 'add-topik'"
          @click="repeateAgain"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Tambah Baru</span>
        </b-button>

        <!-- SAVE BUTTON -->
        <b-button
          variant="info"
          class="float-right"
          @click.prevent="validationForm()"
        >
          Simpan
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BButton,
  VBModal,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  name: "repeatFormModal",
  components: {
    BButton,
    VBModal,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [heightTransition],

  props: {
    data: Object,
  },

  data() {
    return {
      jumlah_data: 1,
      item: [{ id: 1 }],
      group_name: [""],
      next_id: 1,
      required,
    };
  },

  methods: {
    repeateAgain() {
      this.$refs.repeatForm.validate().then((success) => {
        if (success) {
          this.item.push({
            id: (this.next_id += 1),
          });
        }
      });
    },
    removeItem(index) {
      this.item.splice(index, 1);
      delete this.group_name[index];
    },
    validationForm() {
      this.$refs.repeatForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>